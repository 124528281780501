import { useI18n } from "@shopify/react-i18n";
import { useApplicationContext } from "../contexts/application.context";
import en from "../translations/en.json";
import fr from "../translations/fr.json";

export default function useTranslator(id: string) {
  const { language } = useApplicationContext();
  const [i18n] = useI18n({
    id,
    fallback: fr,
    translations(locale) {
      if (language === "en") {
        return en;
      } else if (language === "fr") {
        return fr;
      }
    },
  });

  return i18n;
}
